import { Controller } from "@hotwired/stimulus"
import { getCsrfToken } from "@concerto/utilities"

export default class extends Controller {
  //static targets = [ "checkbox", "toggler", "trueOption", "falseOption" ]
  // static classes = [ "hidden", "active", "true", "false" ]

  static classes = [
    "offActive",
    "onActive",
    "hidden"
  ]

  static targets = [
    "spinner",
    "srStatus",
    "offButton",
    "onButton",
    "statusLabel",
    "errorIcon"
  ]
  
  static values = {
    onLabel: String,
    offLabel: String,
    loadingSrMessage: String,
    src: String
  }

  get checked() {
    const { checked } = this.element.dataset
    return checked == "checked"
  }

  set checked(newCheckedValue) {
    if (newCheckedValue) {
      this.element.dataset.checked = "checked"
    } else {
      this.element.dataset.checked = "false"
    }
  }

  setErrored() {
    this.errorIconTarget.classList.remove(this.hiddenClass)
  }

  startLoading() {
    this.errorIconTarget.classList.add(this.hiddenClass)
    this.spinnerTarget.classList.remove(this.hiddenClass)
    this.srStatusTarget.textContent = this.loadingSrMessageValue
    this.onButtonTarget.disabled = true
    this.offButtonTarget.disabled = true
  }

  stopLoading() {
    this.spinnerTarget.classList.add(this.hiddenClass)
    this.srStatusTarget.textContent = ''
    this.onButtonTarget.disabled = false
    this.offButtonTarget.disabled = false
  }

  getFormDataWithValue(value) {
    const formData = new FormData()

    formData.append(
      'value',
      value ? 'true' : 'false'
    )

    return formData
  }

  turnOn() {
    this.startLoading()

    fetch(this.srcValue, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: this.getFormDataWithValue(true)
    }).then(({ status }) => {
      this.stopLoading()
      
      if (status < 200 || status > 299) {
        // failed
        this.setErrored()
      } else {
        // success
        this.markOn()
      }
    }).catch(() => {
      this.stopLoading()
      this.setErrored()
    })
  }

  turnOff() {
    this.startLoading()

    fetch(this.srcValue, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'X-CSRF-Token': getCsrfToken()
      },
      body: this.getFormDataWithValue(false)
    }).then(({ status }) => {
      this.stopLoading()
      
      if (status < 200 || status > 299) {
        // failed
        this.setErrored()
      } else {
        // success
        this.markOff()
      }
    }).catch(() => {
      this.stopLoading()
      this.setErrored()
    })
  }

  markOn() {
    if (!this.checked) {
      this.offButtonTarget.classList.remove(this.offActiveClass)
      this.onButtonTarget.classList.add(this.onActiveClass)
      this.statusLabelTarget.textContent = this.onLabelValue
      this.checked = true
    }
  }

  markOff() {
    if (this.checked) {
      this.onButtonTarget.classList.remove(this.onActiveClass)
      this.offButtonTarget.classList.add(this.offActiveClass)
      this.statusLabelTarget.textContent = this.offLabelValue
      this.checked = false
    }
  }
}